import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

const PitcherSearch = ({ pitcherData, setPitcherSearch, handleAddPitcher }) => (
  <Autocomplete
    options={pitcherData.map(p => p.Name)}
    onInputChange={(event, value) => setPitcherSearch(value)}
    onChange={(event, value) => handleAddPitcher(value)}
    renderInput={(params) => (
      <TextField {...params} label="Search Pitchers" variant="outlined" fullWidth />
    )}
    noOptionsText="No Results Found"
    sx={{ width: '45%' }}
  />
);

export default PitcherSearch;
