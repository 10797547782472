// App.js
import React, { useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { Box, CssBaseline } from '@mui/material';
import { ThemeContextProvider } from './ThemeContext';
import Sidebar from './components/Sidebar';
import StrikeoutSuite from './pages/StrikeoutSuite';
import LiveBaseball from './pages/LiveBaseball'; // Add this import

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(true);

  return (
    <ThemeContextProvider>
      <CssBaseline />
      <Router>
        <Box sx={{ display: 'flex' }}>
          <Sidebar open={sidebarOpen} setOpen={setSidebarOpen} />
          <Box component="main" sx={{ flexGrow: 1, p: 3, position: 'relative' }}>
          <Routes>
            <Route path="/" element={<Navigate to="/nrfi-suite" replace />} />
            <Route path="/strikeout-suite" element={<StrikeoutSuite />} />
            <Route path="/nrfi-suite" element={<LiveBaseball />} />
          </Routes>
          </Box>
        </Box>
      </Router>
    </ThemeContextProvider>
  );
}

export default App;