import React from 'react';
import { Autocomplete, TextField } from '@mui/material';

const BatterSearch = ({ batterData, setBatterSearch, handleAddBatter }) => (
  <Autocomplete
    options={batterData.map(b => b.Name)}
    onInputChange={(event, value) => setBatterSearch(value)}
    onChange={(event, value) => handleAddBatter(value)}
    renderInput={(params) => (
      <TextField {...params} label="Search Batters" variant="outlined" fullWidth />
    )}
    noOptionsText="No Results Found"
    sx={{ width: '45%' }}
  />
);

export default BatterSearch;
