import React from 'react';
import { Paper, Box, Typography } from '@mui/material';
import teamLogos from './teamLogos'; // Adjust the path as needed

const GameLineup = ({ game, onLineupSelect }) => {
  const AwayLogo = teamLogos[game.awayTeam];
  const HomeLogo = teamLogos[game.homeTeam];

  return (
    <Paper
      elevation={3}
      sx={{
        p: 1,
        width: 150,
        minWidth: 150,
        height: 60,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        border: '1px solid',
        borderColor: 'grey.500',
        '&:hover': { backgroundColor: 'action.hover' },
      }}
      onClick={() => onLineupSelect(game)}
    >
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: 1 }}>
        {AwayLogo && <AwayLogo size={60} />}
        <Typography variant="body2">@</Typography>
        {HomeLogo && <HomeLogo size={60} />}
      </Box>
    </Paper>
  );
};

export default GameLineup;
