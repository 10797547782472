import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { Box, Typography, Paper, Button, useTheme, CircularProgress } from '@mui/material';
import { styled } from '@mui/material/styles';
import html2canvas from 'html2canvas';
import logo from './logo192.jpg';

// Styled components
const StyledTable = styled('table')(({ theme }) => ({
  borderCollapse: 'collapse',
  margin: '20px 0',
  fontSize: '1.35em',
  minWidth: '400px',
  width: '100%',
  boxShadow: '0 0 20px rgba(0, 0, 0, 0.15)',
  '& thead tr, & th': {
    backgroundColor: '#009879', // Fixed green color
    color: '#ffffff', // White text for contrast
    textAlign: 'left',
  },
  '& th, & td': {
    padding: '12px 15px',
  },
  '& tbody tr': {
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:last-of-type': {
      borderBottom: `2px solid ${theme.palette.primary.main}`,
    },
    '&.highlight': {
      backgroundColor: theme.palette.mode === 'dark' 
        ? 'rgba(116, 0, 224, 0.8)' 
        : 'rgba(116, 0, 224, 0.8)',
      color: theme.palette.getContrastText(theme.palette.mode === 'dark' 
        ? 'rgba(116, 0, 224, 0.8)' 
        : 'rgba(116, 0, 224, 0.8)'),
    },
  },
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  marginBottom: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
}));

const StyledButton = styled(Button)(({ theme }) => ({
  margin: theme.spacing(1),
}));

const PredictionTable = styled(StyledTable)(({ theme }) => ({
'& th': {
    backgroundColor: '#009879', // Fixed green color
    color: '#ffffff', // White text for contrast
    textAlign: 'left',
  },
}));

const PitcherDataTable = styled(StyledTable)(({ theme }) => ({
    '& th': {
        backgroundColor: '#009879', // Fixed green color
        color: '#ffffff', // White text for contrast
        textAlign: 'left',
      },
    '& .matchup-header': {
        textAlign: 'center',
        fontWeight: 'bold',
        color: theme.palette.common.white,
        backgroundColor: theme.palette.grey[900],
        padding: theme.spacing(1),
    },
    '& .highlight-nrfi': {
        backgroundColor: theme.palette.mode === 'dark' 
            ? 'rgba(116, 0, 224, 0.8)' 
            : 'rgba(116, 0, 224, 0.8)',
        color: theme.palette.getContrastText(theme.palette.mode === 'dark' 
            ? 'rgba(116, 0, 224, 0.8)' 
            : 'rgba(116, 0, 224, 0.8)'),
    },
}));

function extractGames(data) {
    const games = [];
    if (!Array.isArray(data)) {
        console.error('Data is not an array:', data);
        return games;
    }
    data.slice(1).forEach(row => {
        if (row.length === 4) {
            games.push({
                id: parseInt(row[0], 10),
                awayTeam: row[1].trim(),
                homeTeam: row[2].trim(),
                time: row[3].trim()
            });
        }
    });
    return games;
}

function groupMatchupsByMatchupField(matchups) {
    const groupedMatchups = {};
    matchups.forEach(matchup => {
        if (!groupedMatchups[matchup.matchup]) {
            groupedMatchups[matchup.matchup] = [];
        }
        groupedMatchups[matchup.matchup].push(...matchup.teams);
    });
    return Object.keys(groupedMatchups).map(key => ({
        matchup: key,
        teams: groupedMatchups[key]
    }));
}

function parseGameDetails(data) {
    if (typeof data !== 'string') {
        console.error('Game details data is not a string:', data);
        return null;
    }
    return data.split('\n').map((line, index) => <pre key={index}>{line}</pre>);
}

function LiveBaseball() {
    const theme = useTheme();
    const [schedule, setSchedule] = useState([]);
    const [gameID, setGameID] = useState('');
    const [gameDetails, setGameDetails] = useState('');
    const [games, setGames] = useState([]);
    const [modelResults, setModelResults] = useState(null);
    const [allModelResults, setAllModelResults] = useState([]);
    const [matchups, setMatchups] = useState([]);
    const [width, setWidth] = useState(window.innerWidth);
    const [message, setMessage] = useState('Please wait while data is loaded...');
    const [isDataLoaded, setIsDataLoaded] = useState(false);
    const [isTableLoading, setIsTableLoading] = useState(false);
    const [logoDataUrl, setLogoDataUrl] = useState('');
    const [isMatchupsLoading, setIsMatchupsLoading] = useState(false);
    const tableRef = useRef(null);
    const overallPitcherContainerRef = useRef(null)

    const setBackgroundColorRecursively = (element, color) => {
        element.style.backgroundColor = color;
        for (let child of element.children) {
          setBackgroundColorRecursively(child, color);
        }
      };

    const today = new Date().toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    });

    useEffect(() => {
        axios.get('https://nrfiswaggerapi.onrender.com/run-nrfi-script')
            .then(response => {
                setMessage('Data successfully loaded');
                setIsDataLoaded(true);
            })
            .catch(error => {
                console.error('Error running script:', error);
                setMessage('Failed to run script');
                setIsDataLoaded(false);
            });

        axios.get('https://nrfiswaggerapi.onrender.com/games-today')
            .then(response => {
                console.log('Schedule response data:', response.data);
                if (Array.isArray(response.data)) {
                    setSchedule(response.data);
                    const parsedGames = extractGames(response.data);
                    setGames(parsedGames);
                } else {
                    console.error('Unexpected data format:', response.data);
                }
            })
            .catch(error => console.error('Error loading schedule:', error));

        const handleResize = () => {
            setWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        const loadLogo = async () => {
            const response = await fetch(logo);
            const blob = await response.blob();
            const reader = new FileReader();
            reader.onloadend = () => {
                setLogoDataUrl(reader.result);
            };
            reader.readAsDataURL(blob);
        };
        loadLogo();
    }, []);

    const handleDetailsSubmit = async (event) => {
        event.preventDefault();
        const game = games.find(g => g.id === parseInt(gameID, 10));
        if (game) {
            try {
                console.log(game);
                handlePredictOutcome(game.awayTeam, game.homeTeam);
                const response = await axios.get('https://nrfiswaggerapi.onrender.com/boxscore', {
                    params: { game_id: gameID }
                });
                console.log('Game details response:', response.data);
                setGameDetails(response.data);
            } catch (error) {
                console.error('Error fetching game details or running predictions:', error);
            }
        } else {
            console.error('No game found with that ID');
        }
    };

    const handlePredictOutcome = async (teamA, teamB) => {
        try {
            console.log(`Predicting outcome for: ${teamA} vs ${teamB}`);
            const response = await axios.get('https://nrfiswaggerapi.onrender.com/predict-outcome', {
                params: { team_a: teamA, team_b: teamB }
            });
            console.log(response.data);
            setModelResults(response.data);
        } catch (error) {
            console.error('Error running predictions:', error.response ? error.response.data : error.message);
        }
    };

    const handlePredictAllOutcomes = async () => {
        try {
            setIsTableLoading(true);
            const results = [];
            for (const game of games) {
                const response = await axios.get('https://nrfiswaggerapi.onrender.com/predict-outcome', {
                    params: { team_a: game.homeTeam, team_b: game.awayTeam }
                });
                results.push({
                    time: game.time,
                    game: `${game.awayTeam} @ ${game.homeTeam}`,
                    nrfi: response.data.nrfi_prediction.toFixed(2),
                    yrfi: response.data.yrfi_prediction.toFixed(2)
                });
            }
            setAllModelResults(results);
            setIsTableLoading(false);
        } catch (error) {
            console.error('Error running predictions for all games:', error);
            setIsTableLoading(false);
        }
    };

    const handleFetchMatchups = async () => {
        try {
            setIsMatchupsLoading(true);
            const response = await axios.get('https://nrfiswaggerapi.onrender.com/process-data');
            console.log('Raw matchups data:', response.data);
            const processedMatchups = [];
            let currentMatchup = null;
            
            response.data.forEach((item, index) => {
                console.log('Processing item:', item);
                if (item.hasOwnProperty('matchup')) {
                    if (currentMatchup) {
                        processedMatchups.push(currentMatchup);
                    }
                    currentMatchup = {
                        matchup: item["matchup"].replace(/,$/, ''),
                        teams: []
                    };
                }
                if (currentMatchup) {
                    if (item['Starting Pitcher']) {
                        item['Starting Pitcher'] = item['Starting Pitcher'].split(', ').reverse().join(' ');
                    }
                    currentMatchup.teams.push(item);
                }
            });
            
            if (currentMatchup) {
                processedMatchups.push(currentMatchup);
            }
    
            const groupedMatchups = groupMatchupsByMatchupField(processedMatchups);
            console.log('Grouped matchups:', groupedMatchups);
            setMatchups(groupedMatchups);
            setIsMatchupsLoading(false);
        } catch (error) {
            console.error('Error fetching matchups:', error);
            setIsMatchupsLoading(false);
        }
    };
    
    const handleExportTable = () => {
        const today = new Date().toISOString().split('T')[0];
        const fileName = `NRFI_MODEL_${today}.jpg`;
    
        if (tableRef.current) {
            const node = tableRef.current;
            const originalBackground = node.style.background;
            const originalPadding = node.style.padding;
    
            // Apply black background and padding to the container
            node.style.background = 'black';
            node.style.padding = '20px'; // Adjust as needed
    
            html2canvas(node, { 
                scale: window.devicePixelRatio || 2,
                logging: false,
                useCORS: true,
                backgroundColor: null // This allows the background to be transparent
            }).then((canvas) => {
                // Restore original styles
                node.style.background = originalBackground;
                node.style.padding = originalPadding;
    
                const dataUrl = canvas.toDataURL('image/jpeg', 1.0);
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((error) => {
                // Restore original styles
                node.style.background = originalBackground;
                node.style.padding = originalPadding;
                console.error('Oops, something went wrong!', error);
            });
        }
    };
    
    const handleExportPitchingTable = () => {
        const today = new Date().toISOString().split('T')[0];
        const fileName = `NRFI_PITCHER_DATA_${today}.jpg`;
    
        if (overallPitcherContainerRef.current) {
            const node = overallPitcherContainerRef.current;
            const originalBackground = node.style.background;
            const originalPadding = node.style.padding;
    
            // Apply black background and padding to the container
            node.style.background = 'black';
            node.style.padding = '20px'; // Adjust as needed
    
            html2canvas(node, { 
                scale: window.devicePixelRatio || 2,
                logging: false,
                useCORS: true,
                backgroundColor: null // This allows the background to be transparent
            }).then((canvas) => {
                // Restore original styles
                node.style.background = originalBackground;
                node.style.padding = originalPadding;
    
                const dataUrl = canvas.toDataURL('image/jpeg', 1.0);
                const link = document.createElement('a');
                link.href = dataUrl;
                link.download = fileName;
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((error) => {
                // Restore original styles
                node.style.background = originalBackground;
                node.style.padding = originalPadding;
                console.error('Oops, something went wrong!', error);
            });
        }
    };

    return (
        <Box sx={{
            minHeight: '100vh',
            bgcolor: theme.palette.background.default,
            color: theme.palette.text.primary,
            p: 2,
        }}>
            <StyledPaper>
                <Typography variant="body1" sx={{
                    color: isDataLoaded ? theme.palette.success.main : theme.palette.info.main,
                    mb: 2
                }}>
                    {message}
                </Typography>
            </StyledPaper>
            
            {isDataLoaded && schedule.length > 0 && (
                <>
                    <Box sx={{ textAlign: 'center', mb: 2 }}>
                        <Typography variant="h4" gutterBottom>Today's Baseball Games</Typography>
                    </Box>
                    <StyledPaper>
                        <StyledTable>
                            <thead>
                                <tr>
                                    {schedule[0].map((header, index) => (
                                        <th key={index}>{header}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {schedule.slice(1).map((row, index) => (
                                    <tr key={index}>
                                        {row.map((cell, cellIndex) => (
                                            <td key={cellIndex}>{cell}</td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </StyledTable>
                    </StyledPaper>
                    
                    <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                        <StyledButton variant="contained" color="primary" onClick={handlePredictAllOutcomes}>
                            Get Predictions for All Games
                        </StyledButton>
                        <StyledButton variant="contained" color="primary" onClick={handleFetchMatchups}>
                            Get Pitcher Analysis
                        </StyledButton>
                    </Box>
                    
                    {isTableLoading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                            <CircularProgress />
                        </Box>
                    )}
                    
                    {allModelResults.length > 0 && (
                        <StyledPaper ref={tableRef}>
                            <Box sx={{ textAlign: 'center', mb: 2 }}>
                                <Typography variant="h5" gutterBottom>
                                    Slide MLB NRFI Predictions - {today}
                                    {logoDataUrl && <img src={logoDataUrl} alt="Logo" style={{ height: '30px', marginLeft: '10px', verticalAlign: 'middle' }} />}
                                </Typography>
                                <Typography variant="h6" gutterBottom>
                                    @SLIDE__SPORTS on X - Daily Free Sheets!
                                </Typography>
                                <Typography variant="body2" gutterBottom>Anything with a NRFI % greater than 75% is good to trail.</Typography>
                                <Typography variant="body2" gutterBottom>The highlighted games are the most probable games for an NRFI outcome based on the models predictions!</Typography>
                                <Typography variant="body2" gutterBottom>It's worth pairing this sheet with the Pitching sheet for an overall look at the games</Typography>
                            </Box>
                            <PredictionTable>
                                <thead>
                                    <tr>
                                        <th>Time</th>
                                        <th>Game</th>
                                        <th>NRFI %</th>
                                        <th>YRFI %</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {allModelResults.map((result, index) => (
                                        <tr key={index} className={parseFloat(result.nrfi) >= 75 ? 'highlight' : ''}>
                                            <td>{result.time}</td>
                                            <td>{result.game}</td>
                                            <td>{result.nrfi}</td>
                                            <td>{result.yrfi}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </PredictionTable>
                        </StyledPaper>
                    )}
                    
                    {allModelResults.length > 0 && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <StyledButton variant="contained" color="secondary" onClick={handleExportTable}>
                                Export Table as Image
                            </StyledButton>
                        </Box>
                    )}
    
                    {isMatchupsLoading && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', my: 2 }}>
                            <CircularProgress />
                        </Box>
                    )}
    
                    {matchups.length > 0 && (
                        <StyledPaper ref={overallPitcherContainerRef}>
                        <Box sx={{ textAlign: 'center', mb: 2 }}>
                            <Typography variant="h5" gutterBottom>
                                Slide MLB NRFI Pitcher Data - {today}
                                {logoDataUrl && <img src={logoDataUrl} alt="Logo" style={{ height: '30px', marginLeft: '10px', verticalAlign: 'middle' }} />}
                            </Typography>
                            <Typography variant="h6" gutterBottom>
                                @SLIDE__SPORTS on X - Daily Free Sheets!
                            </Typography>
                            <Typography variant="body2" gutterBottom>If there's ever any missing data, please tweet me @Slide__Sports!</Typography>
                        </Box>
                            <Box sx={{ overflowX: 'auto' }}>
                                <PitcherDataTable>
                                    <thead>
                                        <tr>
                                            <th>Starting Pitcher</th>
                                            <th>Strikeout Rate</th>
                                            <th>WHIP</th>
                                            <th>Walk Rate</th>
                                            <th>NRFI Streak</th>
                                            <th>Team to Face</th>
                                            <th>Batting Team AVG</th>
                                            <th>Batting Team NRFI Record (2024)</th>
                                            <th>Batting Team OBP</th>
                                            <th>NRFI Rating</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {matchups.map((matchup, index) => (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td colSpan="10" className="matchup-header">{matchup.matchup}</td>
                                            </tr>
                                            {matchup.teams.map((team, teamIndex) => {
                                                const isNoDataFound = team["No data found"];
                                                const isHighlighted = parseFloat(team["NRFI Rating"]) >= 85;
                                                return isNoDataFound ? (
                                                    <tr key={teamIndex}>
                                                        <td>{team["Starting Pitcher"]}</td>
                                                        <td colSpan="9" style={{textAlign: 'center'}}>No data found, please reach out on X! @Slide__Sports</td>
                                                    </tr>
                                                ) : (
                                                    <tr key={teamIndex} className={isHighlighted ? 'highlight-nrfi' : ''}>
                                                        <td>{team["Starting Pitcher"]}</td>
                                                        <td>{team["Strikeout Rate"] || "No data found"}</td>
                                                        <td>{team["WHIP"] || "No data found"}</td>
                                                        <td>{team["Walk Rate"] || "No data found"}</td>
                                                        <td>{team["Current Streak"]}</td>
                                                        <td>{team["Team to NRFI"] || "No data found"}</td>
                                                        <td>{team["Batting Team AVG"] || "No data found"}</td>
                                                        <td>{team["Batting Team NRFI Record 2024"] || "No data found"}</td>
                                                        <td>{team["Batting Team OBP"] || "No data found"}</td>
                                                        <td>{team["NRFI Rating"] || "No data found"}</td>
                                                    </tr>
                                                );
                                            })}
                                        </React.Fragment>
                                    ))}
                                    </tbody>
                                </PitcherDataTable>
                            </Box>
                        </StyledPaper>
                    )}
                    
                    {matchups.length > 0 && (
                        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
                            <StyledButton variant="contained" color="secondary" onClick={handleExportPitchingTable}>
                                Export Pitcher Data as Image
                            </StyledButton>
                        </Box>
                    )}
                </>
            )}
        </Box>
    );
}

export default LiveBaseball;