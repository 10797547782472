import * as Logos from 'react-mlb-logos';

const teamLogos = {
  ARI: Logos.ARI,
  ATL: Logos.ATL,
  BAL: Logos.BAL,
  BOS: Logos.BOS,
  CHC: Logos.CHC,
  CIN: Logos.CIN,
  CLE: Logos.CLE,
  COL: Logos.COL,
  CWS: Logos.CHW,
  DET: Logos.DET,
  HOU: Logos.HOU,
  KC: Logos.KAN,
  LAA: Logos.LAA,
  LAD: Logos.LAD,
  MIA: Logos.MIA,
  MIL: Logos.MIL,
  MIN: Logos.MIN,
  NYM: Logos.NYM,
  NYY: Logos.NYY,
  OAK: Logos.OAK,
  PHI: Logos.PHI,
  PIT: Logos.PIT,
  SD: Logos.SD,
  SEA: Logos.SEA,
  SF: Logos.SF,
  STL: Logos.STL,
  TB: Logos.TB,
  TEX: Logos.TEX,
  TOR: Logos.TOR,
  WSH: Logos.WAS,
};

export default teamLogos;
