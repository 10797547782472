// Sidebar.js
import React, { useState } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Box, Collapse } from '@mui/material';
import { Link } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ThemeToggle from './ThemeToggle';

const Sidebar = ({ open, setOpen }) => {
  const [mlbOpen, setMlbOpen] = useState(false);

  const handleMlbClick = () => {
    setMlbOpen(!mlbOpen);
  };

  return (
    <Drawer
      variant="permanent"
      open={open}
      sx={{
        width: open ? 240 : 60,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: open ? 240 : 60,
          boxSizing: 'border-box',
          transition: 'width 0.2s',
        },
      }}
    >
      <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <IconButton onClick={() => setOpen(!open)} sx={{ m: 1 }}>
          <MenuIcon />
        </IconButton>
        <List sx={{ flexGrow: 1 }}>
          <ListItem button onClick={handleMlbClick}>
            <ListItemIcon>
              <SportsBaseballIcon />
            </ListItemIcon>
            {open && <ListItemText primary="MLB Tools" />}
            {open && (mlbOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />)}
          </ListItem>
          <Collapse in={mlbOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItem button component={Link} to="/strikeout-suite" sx={{ pl: 4 }}>
                {/* <ListItemIcon>
                  <SportsBaseballIcon />
                </ListItemIcon> */}
                {open && <ListItemText primary="Strikeout Suite" sx = {{textAlign: "center"}} />}
              </ListItem>
              <ListItem button component={Link} to="/nrfi-suite" sx={{ pl: 4 }}>
                {/* <ListItemIcon>
                  <LiveTvIcon />
                </ListItemIcon> */}
                {open && <ListItemText primary="NRFI Suite" sx = {{textAlign: "center"}}/>}
              </ListItem>
            </List>
          </Collapse>
        </List>
        <Box sx={{p: 2 }}>
          <ThemeToggle />
        </Box>
      </Box>
    </Drawer>
  );
};

export default Sidebar;