import React, { useState, useEffect } from 'react';
import { Box, Typography, Paper, List, ListItem, useTheme, Button, Switch, CircularProgress, IconButton  } from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import DeleteIcon from '@mui/icons-material/Delete';
import PitcherSearch from '../components/PitcherSearch';
import BatterSearch from '../components/BatterSearch';
import GameLineup from '../components/GameLineup';
import teamLogos from '../components/teamLogos';
import PoissonDistribution from '../components/PoissonDistribution';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

// Helper function to fetch data from API
const fetchData = async (endpoint) => {
  try {
    const response = await fetch(endpoint);
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error(`Failed to fetch data from ${endpoint}:`, error);
    throw error;
  }
};

const loadLeagueSOAvg = async () => {
  const data = await fetchData('https://nrfiswaggerapi.onrender.com/fetch-advanced-batting');
  if (data.league_average && data.league_average.length > 0) {
    const value = parseFloat(data.league_average[0]['SO%']);
    return value / 100; // Convert percentage to a fraction
  }
  throw new Error('No data found in league_SO_AVG');
};

const calculateLineupStrikeoutAvg = (batters) => {
  if (batters.length === 0) return 0;
  const battersToConsider = batters.slice(0, 9); // Ensure we only consider up to 9 batters
  const totalSO = battersToConsider.reduce((sum, batter) => sum + (parseFloat(batter['SO%']) || 0), 0);
  return totalSO / battersToConsider.length;
};

const StrikeoutSuite = () => {
  const theme = useTheme();
  const [pitcherData, setPitcherData] = useState([]);
  const [batterData, setBatterData] = useState([]);
  const [lineupData, setLineupData] = useState([]);
  const [games, setGames] = useState([]);
  const [selectedPitcher, setSelectedPitcher] = useState(null);
  const [selectedPitcherStatus, setSelectedPitcherStatus] = useState('');
  const [selectedBatters, setSelectedBatters] = useState([]);
  const [activeGame, setActiveGame] = useState(null);
  const [isHomePitching, setIsHomePitching] = useState(true);
  const [currentGameIndex, setCurrentGameIndex] = useState(0);
  const [pitchingTeam, setPitchingTeam] = useState(null);
  const [battingTeam, setBattingTeam] = useState(null);
  const [pitcherSearch, setPitcherSearch] = useState('');
  const [batterSearch, setBatterSearch] = useState('');
  const [noDataMessage, setNoDataMessage] = useState('');
  const [leagueAverageStrikeout, setLeagueAverageStrikeout] = useState(null);
  const [lineupStrikeoutAvg, setLineupStrikeoutAvg] = useState(null);
  const [adjustedAverage, setAdjustedAverage] = useState(null);
  const [bookKs, setBookKs] = useState(4); // New state for bookK's
  const [noLineupMessage, setNoLineupMessage] = useState(''); // New state for no lineups message
  const [isLoading, setIsLoading] = useState(true); // New state for loading indicator
  const [visibleGames, setVisibleGames] = useState(2);
  const [startIndex, setStartIndex] = useState(0);

  const nextGames = () => {
    if (startIndex + visibleGames < games.length) {
      setStartIndex(startIndex + 1);
    }
  };

  const prevGames = () => {
    if (startIndex > 0) {
      setStartIndex(startIndex - 1);
    }
  };

  const removeDuplicateGames = (games) => {
    const uniqueGames = [];
    const seenGames = new Set();

    games.forEach(game => {
      const gameKey = `${game.awayTeam} @ ${game.homeTeam}`;
      if (!seenGames.has(gameKey)) {
        seenGames.add(gameKey);
        uniqueGames.push(game);
      }
    });

    return uniqueGames;
  };

  const getMostRecentEntries = (data) => {
    const mostRecentEntries = new Map();
    data.forEach(entry => {
      mostRecentEntries.set(entry.Name, entry);
    });
    return Array.from(mostRecentEntries.values());
  };

  useEffect(() => {
    const loadAPIData = async () => {
      setIsLoading(true); // Set loading to true
      try {
        const pitcherDataPromise = fetchData('https://nrfiswaggerapi.onrender.com/fetch-pitcher-data');
        const batterDataPromise = fetchData('https://nrfiswaggerapi.onrender.com/fetch-advanced-batting');
        const lineupDataPromise = fetchData('https://nrfiswaggerapi.onrender.com/fetch-daily-lineups');
        const leagueSOAvgPromise = loadLeagueSOAvg();

        const pitchers = await pitcherDataPromise;
        const batters = await batterDataPromise;
        const lineups = await lineupDataPromise;
        const leagueSOAvg = await leagueSOAvgPromise;

        setLeagueAverageStrikeout(leagueSOAvg);

        const filteredPitchers = getMostRecentEntries(pitchers.pitcher_data);
        const filteredBatters = getMostRecentEntries(batters.advanced_batting);

        setPitcherData(filteredPitchers);
        setBatterData(filteredBatters);

        if (lineups.daily_lineups.length === 0) {
          setNoLineupMessage('No Lineups found, please reach out to Slide__Sports on X if this is a genuine error!');
        } else {
          setLineupData(lineups.daily_lineups);

          const gameMap = new Map();
          lineups.daily_lineups.forEach(player => {
            const gameKey = `${player.Team} vs ${player.Opponent}`;
            if (!gameMap.has(gameKey)) {
              gameMap.set(gameKey, {
                homeTeam: player['Home/Away'] === 'Home' ? player.Team : player.Opponent,
                awayTeam: player['Home/Away'] === 'Away' ? player.Team : player.Opponent,
                homeLineup: [],
                awayLineup: []
              });
            }
            const game = gameMap.get(gameKey);
            if (player.Team === game.homeTeam) {
              game.homeLineup.push(player);
            } else {
              game.awayLineup.push(player);
            }
          });

          // Ensure that homeLineup and awayLineup are populated correctly
          gameMap.forEach(game => {
            if (game.homeLineup.length === 0) {
              game.homeLineup = lineups.daily_lineups.filter(player => player.Team === game.homeTeam);
            }
            if (game.awayLineup.length === 0) {
              game.awayLineup = lineups.daily_lineups.filter(player => player.Team === game.awayTeam);
            }
          });

          let gameArray = Array.from(gameMap.values());
          gameArray = gameArray.filter(game => game.homeTeam && game.awayTeam);
          setGames(removeDuplicateGames(gameArray));
        }
      } catch (error) {
        console.error("Failed to load data:", error);
      } finally {
        setIsLoading(false); // Set loading to false after data is loaded
      }
    };

    loadAPIData();
  }, []);

  useEffect(() => {
    if (selectedBatters.length > 0) {
      const avg = calculateLineupStrikeoutAvg(selectedBatters);
      setLineupStrikeoutAvg(avg);
      // Assuming Adjusted Average is based on some combination of calculated values
      const adjAvg = avg / leagueAverageStrikeout;
      setAdjustedAverage(adjAvg);
    }
  }, [selectedBatters, leagueAverageStrikeout]);

  const handleLineupSelect = (game) => {
    console.log("Selected Game:", game);
    setActiveGame(game);
    setIsHomePitching(true); // Reset to home pitching when a new game is selected
    updateLineup(game, true);
  };

  const updateLineup = (game, isHomePitching) => {
    const pitchingLineup = isHomePitching ? game.homeLineup : game.awayLineup;
    const battingLineup = isHomePitching ? game.awayLineup : game.homeLineup;

    setPitchingTeam(isHomePitching ? game.homeTeam : game.awayTeam);
    setBattingTeam(isHomePitching ? game.awayTeam : game.homeTeam);

    console.log("Pitching Lineup:", pitchingLineup);
    console.log("Batting Lineup:", battingLineup);

    const pitcher = pitchingLineup.find(player => player.Position === 'P');
    if (pitcher) {
      const selectedPitcherData = pitcherData.find(p => p.Name === pitcher.Name);
      if (selectedPitcherData) {
        setSelectedPitcher(selectedPitcherData);
        setSelectedPitcherStatus(pitcher['Lineup Status']);
        setNoDataMessage('');
        console.log("Selected Pitcher Data:", selectedPitcherData);
      } else {
        setSelectedPitcher(null);
        setSelectedPitcherStatus('');
        setNoDataMessage(`No Data for ${pitcher.Name} found, please contact @Slide__Sports on X!`);
      }
    } else {
      setSelectedPitcher(null);
      setSelectedPitcherStatus('');
      setNoDataMessage('');
    }

    const batters = battingLineup.filter(player => player.Position !== 'P');
    const selectedBattersData = batters.map(batter => {
      const batterDataEntry = batterData.find(b => b.Name === batter.Name);
      return {
        ...batterDataEntry,
        LineupStatus: batter['Lineup Status']
      };
    }).filter(Boolean);
    setSelectedBatters(selectedBattersData);
    console.log("Selected Batters Data:", selectedBattersData);
  };

  const toggleTeam = () => {
    if (activeGame) {
      const newIsHomePitching = !isHomePitching;
      setIsHomePitching(newIsHomePitching);
      updateLineup(activeGame, newIsHomePitching);
    }
  };

  const handleAddPitcher = (pitcherName) => {
    const pitcher = pitcherData.find(p => p.Name === pitcherName);
    if (pitcher) {
      const lineupEntry = lineupData.find(p => p.Name === pitcherName);
      setSelectedPitcher({
        ...pitcher,
        LineupStatus: lineupEntry ? lineupEntry['Lineup Status'] : 'Expected Lineup'
      });
      setNoDataMessage('');
    } else {
      setSelectedPitcher(null);
      setNoDataMessage(`No Data for ${pitcherName} found, please contact @Slide__Sports on X!`);
    }
  };

  const handleAddBatter = (batterName) => {
    const batter = batterData.find(b => b.Name === batterName);
    if (batter && !selectedBatters.find(b => b.Name === batter.Name)) {
      const lineupEntry = lineupData.find(b => b.Name === batterName);
      setSelectedBatters(prev => [
        ...prev,
        {
          ...batter,
          LineupStatus: lineupEntry ? lineupEntry['Lineup Status'] : 'Expected Lineup'
        }
      ]);
    }
  };

  const handleRemoveBatter = (batterName) => {
    setSelectedBatters(prev => prev.filter(b => b.Name !== batterName));
  };

  const getPredictedStrikeouts = () => {
    if (!selectedPitcher || adjustedAverage === null) return null;
    return (
      (parseFloat(selectedPitcher['Pitching SO%']) *
        parseFloat(adjustedAverage) *
        parseFloat(selectedPitcher.xInnings) *
        parseFloat(selectedPitcher.xBF)) /
      100
    );
  };

  return (
    <Box sx={{
      minHeight: '100vh',
      width: '100%',
      maxWidth: '100vw',
      padding: { xs: 1, sm: 2 },
      display: 'flex',
      flexDirection: 'column',
      overflow: 'hidden',
      bgcolor: theme.palette.background.default,
      color: theme.palette.text.primary,
    }}>
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        width: '100%',
        mb: 2,
      }}>
        <IconButton onClick={prevGames} disabled={startIndex === 0}>
          <ArrowBackIosNewIcon />
        </IconButton>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center',
          gap: 1,
          flex: 1,
        }}>
          {games.slice(startIndex, startIndex + visibleGames).map((game, index) => (
            <GameLineup 
              key={index} 
              game={game} 
              onLineupSelect={handleLineupSelect}
              sx={{ 
                flex: 1,
                maxWidth: '45%',
                fontSize: '0.7rem',
                padding: '4px',
              }}
            />
          ))}
        </Box>
        <IconButton onClick={nextGames} disabled={startIndex + visibleGames >= games.length}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Box>
      
      <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
        {isLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress />
          </Box>
        ) : noLineupMessage ? (
          <Typography variant="body1" align="center" sx={{ mt: 2 }}>
            {noLineupMessage}
          </Typography>
        ) : activeGame && (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
          }}>
            <Typography variant="h6" gutterBottom sx={{ fontSize: '1rem', textAlign: 'center' }}>
              {pitchingTeam} (Pitching) vs {battingTeam} (Batting)
            </Typography>
            <Switch
              checked={!isHomePitching}
              onChange={toggleTeam}
              inputProps={{ 'aria-label': 'Toggle Team' }}
              sx={{ mb: 1 }}
            />
            <Box sx={{ width: '100%', mb: 2 }}>
              <PitcherSearch
                pitcherData={pitcherData}
                setPitcherSearch={setPitcherSearch}
                handleAddPitcher={handleAddPitcher}
              />
              <BatterSearch
                batterData={batterData}
                setBatterSearch={setBatterSearch}
                handleAddBatter={handleAddBatter}
              />
            </Box>
            <Box sx={{
              display: 'flex',
              flexDirection: { xs: 'column', md: 'row' },
              justifyContent: 'space-between',
              width: '100%',
              gap: 2,
            }}>
              <Paper elevation={3} sx={{ p: 2, width: { xs: '100%', md: '45%' } }}>
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '1.2rem', sm: '1.4rem', md: '1.6rem' } }}>
                  Pitcher
                  {pitchingTeam && teamLogos[pitchingTeam] && React.createElement(teamLogos[pitchingTeam], { size: 30, style: { marginLeft: 8 } })}
                  ({pitchingTeam})
                </Typography>
                {selectedPitcher ? (
                  <Box>
                    <Typography sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold'}}>
                      {selectedPitcher.Name}
                      <CircleIcon sx={{ 
                        color: selectedPitcherStatus === 'Confirmed Lineup' ? 'green' : 'yellow', 
                        fontSize: 14, 
                        ml: 1 
                      }} />
                    </Typography>
                    <Typography>Expected Innings: {parseFloat(selectedPitcher.xInnings).toFixed(2)}</Typography>
                    <Typography>Expected Batters Faced Per Inning: {parseFloat(selectedPitcher.xBF).toFixed(2)}</Typography>

                    {selectedPitcher && adjustedAverage !== null && (
                      <>
                        <Typography>
                          Predicted Strikeouts: {getPredictedStrikeouts().toFixed(2)}
                        </Typography>
                        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                          <Typography sx={{ mr: 1 }}>Strikeout Line:</Typography>
                          <input
                            type="number"
                            value={bookKs}
                            onChange={(e) => setBookKs(Math.max(0, parseInt(e.target.value) || 0))}
                            style={{ width: '50px' , height: '50px'}}
                          />
                        </Box>
                        <PoissonDistribution
                          predictedSO={getPredictedStrikeouts()}
                          bookKs={bookKs}
                        />
                      </>
                    )}
                    
                  </Box>
                ) : (
                  <Typography>{noDataMessage}</Typography>
                )}
              </Paper>
              <Paper elevation={3} sx={{ p: 2, width: { xs: '100%', md: '45%' } }}>
                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', fontSize: { xs: '1rem', sm: '1.2rem', md: '1.4rem' } }}>
                  Batters
                  {battingTeam && teamLogos[battingTeam] && React.createElement(teamLogos[battingTeam], { size: 30, style: { marginLeft: 8 } })}
                  ({battingTeam})
                </Typography>
                <List>
                  {selectedBatters.map((batter, index) => (
                    <ListItem key={index} sx={{ display: 'flex',alignItems: 'center' , justifyContent: 'space-between' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography sx={{ display: 'flex', alignItems: 'center' }}>
                          {batter.Name}
                          <CircleIcon sx={{ 
                            color: batter.LineupStatus === 'Confirmed Lineup' ? 'green' : 'yellow', 
                            fontSize: 12, 
                            ml: 1 
                          }} />
                        </Typography>
                      </Box>
                      <Typography sx={{ ml: 1, alignItems: 'left' }}>Strikeout %: {parseFloat(batter['SO%']).toFixed(2)}</Typography>
                      <Button onClick={() => handleRemoveBatter(batter.Name)}><DeleteIcon /></Button>
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default StrikeoutSuite;
