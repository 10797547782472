import React, { useMemo } from 'react';
import { Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const PoissonDistribution = ({ predictedSO, bookKs }) => {
  const MAX_STRIKEOUTS = 19;

  const factorial = (n) => {
    if (n === 0 || n === 1) return 1;
    return n * factorial(n - 1);
  };

  const poissonProbabilities = useMemo(() => {
    return Array.from({ length: MAX_STRIKEOUTS + 1 }, (_, k) => {
      const probability = (Math.exp(-predictedSO) * Math.pow(predictedSO, k)) / factorial(k);
      return { k, probability };
    });
  }, [predictedSO]);

  const probabilitySum = useMemo(() => {
    return poissonProbabilities
      .filter(({ k }) => k >= bookKs)
      .reduce((sum, { probability }) => sum + probability, 0);
  }, [poissonProbabilities, bookKs]);

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6">Strikeout Prediction</Typography>
      <Typography sx={{ fontWeight: 'bold'}}>
        Probability of {bookKs}+ strikeouts: {(probabilitySum * 100).toFixed(2)}%
      </Typography>
    </Box>
  );
};

export default PoissonDistribution;